import { CircularProgress } from "@material-ui/core";
import React from "react";
import { isLoggedIn, redirect } from "../utils";

export default function RedirectIfLoggedIn({children}){
    if(isLoggedIn()){
        redirect()
        return <CircularProgress size={56}/>
    }
    return <React.Fragment> {children} </React.Fragment>
}