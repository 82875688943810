/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Box, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { l1 } from './local.json';
import Cookies from 'universal-cookie';
import { redirect } from './utils';
import PageTitle from './components/PageTitle';
import { useAuthStyles } from './style';
import RoutedAlert from './components/RoutedAlert';

const LOGIN = gql`
  mutation Login($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
      user {
        username
        id
        confirmed
      }
    }
  }
`;

const validationSchema = Yup.object().shape({
  identifier: Yup.string()
    .required(l1.emailRequired)
    .email(l1.validEmail),
  password: Yup.string()
    .required(l1.passwordRequired),
});

const cookies = new Cookies();

export default function Login() {
  const [rememberMe, setRememberMe] = useState(true)

  const [login, {loading, error}] = useMutation(LOGIN, {
    onError: (err) => {
      console.error(err)
    },
    onCompleted: (data) => {
      let cookieParams = {
        domain: process.env.REACT_APP_SESSION_COOKIE_DOMAIN || 'localhost',
        path: '/',
      }
      if(rememberMe){
        cookieParams.maxAge = 3600*24*28// Cookie alive for 28 days
      }
      cookies.set('token', data.login.jwt, cookieParams);
      redirect()
    },
  });

  const classes = useAuthStyles();
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar
          className={classes.avatar}
          src={process.env.PUBLIC_URL + 'logo192.png'}
        />
        <Box justifyContent="center" margin={2}>
          <Link variant="body2" to={`/register${window.location.search}`} className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" >
            {l1.notRegistered}
          </Link>
        </Box>
        <RoutedAlert />

        <PageTitle title={l1.logIn} />

        <Formik 
          initialValues={{
            identifier: '',
            password: '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            login({
              variables: {
                identifier: values.identifier,
                password: values.password,
              },
            });
          }}
        >
          {({ handleBlur, values, touched, handleChange, errors, handleSubmit }) => (
            <form
              className={classes.form}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{
                  style:{ direction: 'ltr'}
                }}
                label={l1.email}
                name="identifier"
                autoComplete="email"
                autoFocus
                onBlur={handleBlur}
                onKeyUp={handleBlur}
                onChange={handleChange}
                value={values.identifier}
                error={Boolean(touched.identifier && errors.identifier)}
                helperText={touched.identifier && errors.identifier}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label={l1.password}
                type="password"
                autoComplete="password"
                onBlur={handleBlur}
                onKeyUp={handleBlur}
                onChange={handleChange}
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <Link to={`/forgot-password${window.location.search}`} variant="body2" className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary">
                {l1.forgotPassword}
              </Link>
              <Typography style={{ color: 'red' }}>{
                error?.graphQLErrors[0]?.extensions?.exception?.data?.data?.[0].messages?.[0]?.id === 'Auth.form.error.confirmed' ? l1.accountNotActivated
                : error?.graphQLErrors[0]?.extensions?.exception?.data?.data?.[0].messages?.[0]?.id === 'Auth.form.error.invalid' ? l1.invalidCredentials
                : error ? l1.errorLogginIn
                : null
              }</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                  name="remember"
                  color="primary"
                  checked={rememberMe}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={(e)=>{setRememberMe(e.target.checked)}}
                  />
                }
                label={l1.rememberme}
              />
              <Button type="submit" disabled={loading || Object.keys(errors).length > 0} fullWidth variant="contained" color="primary" className={classes.submit}>
                <Typography className={classes.submitLabel}>
                  { loading && <CircularProgress color="default" size={20} /> }
                  {l1.login}
                </Typography>
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
