import { l1 } from '../local.json'
import Alert from '@material-ui/lab/Alert';

export default function RoutedAlert(){
  const message = new URLSearchParams(window.location.search).get('message')?.split('.')
  if(message?.length !== 2) {
    return null;
  }
  
  let [type, key] = message;
  type = ['info', 'success', 'error'].includes(type) ? type : 'info';
  return (
    <Alert severity={type} style={{margin:16, fontSize: 18, lineHeight:1.5, width:'100%', textAlign:'center'}}>
      {l1[key]}
    </Alert>
  )
}