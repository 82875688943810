import { Avatar, Box, Container, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import PageTitle from './components/PageTitle'
import {l1} from './local.json'
import { useAuthStyles } from './style'
async function logoutFromAcademy(setLoggedOut){
  console.log( process.env.REACT_APP_ACADEMY_LOGOUT_URL)
  await fetch(process.env.REACT_APP_ACADEMY_LOGOUT_URL)
  .then(res => {
    if (res.status < 400){
      console.log("Logout status code:", res.status)
    }        
  })
  .catch(err => console.log('Logout from Academy error:', err))
  .finally(()=>{
    setLoggedOut(true);
  })
}

function Logout() {
  const classes = useAuthStyles();
  const [loggedOut, setLoggedOut] = useState(false)
  useEffect(
    () => {
      // 1. Remove the token cookie
      const cookies = new Cookies();
      cookies.remove(
          'token',
          {
              domain: process.env.REACT_APP_SESSION_COOKIE_DOMAIN || 'localhost',
              path: '/',
          }
      )
      // 2. Request logout from Academy
      logoutFromAcademy(setLoggedOut)
    }, [])
  if (loggedOut){
    window.location.href = process.env.REACT_APP_WEBSITE_URL;
  }
  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar
          className={classes.avatar}
          src={process.env.PUBLIC_URL + 'logo192.png'}
        />
        <PageTitle title={`${l1.loggedOutFrom}\n${process.env.REACT_APP_PLATFORM_NAME}`} />

        <Typography comoponent='p' align='center'>
          {l1.redirectInShortMoment}.
          <br/><br/>
          {l1.ifYouCantWait}{' '}<br/>
          <a href={process.env.REACT_APP_WEBSITE_URL}>{l1.backToHomePage}</a>
        </Typography>
      </div>
    </Container>
  )
}

export default Logout