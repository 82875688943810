import Cookies from "universal-cookie";

export function isLoggedIn(){
  return new Cookies().get('token')?.split('.').length === 3
}

export function redirect(){
  // get and format redirect url
  const appendHome = new URLSearchParams(window.location.search).get('center');
  let redirectTo = new URLSearchParams(window.location.search).get('redirect_to');
  if (appendHome) {
    let urlObject = new URL(document.referrer);
    urlObject.pathname = appendHome && urlObject.pathname === '/' ? '/home' : urlObject.pathname;
    redirectTo = urlObject.toString()
  }
  window.location.href = decodeURI(redirectTo || process.env.REACT_APP_WEBSITE_URL);
}