/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Box, CircularProgress} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { l1 } from './local.json';
import PageTitle from './components/PageTitle';
import { useAuthStyles } from './style';

const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ok
  }
}

`;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required(l1.emailRequired)
    .email(l1.validEmail),
});

export default function ForgotPassword() {
  const history = useHistory();
  const [emailChanged, setEmailChanged] = useState(false);
  const [forgotPassword, {loading, error, }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: (data) => { 
      setEmailChanged(false);
      history.push('/?message=success.passwordResetEmailSent');
    },
    onError: (err) => { console.log(err); setEmailChanged(false)},
  });
  window.err = error
  const classes = useAuthStyles();
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar
          className={classes.avatar}
          src={process.env.PUBLIC_URL + 'logo192.png'}
        />
        <Box justifyContent="center" margin={2}>
          <Link variant="body2" to={`/${window.location.search}`} className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" >
            {l1.backToLogin}
          </Link>
        </Box>
        <PageTitle title={l1.passwordSupport} />
        <Typography comoponent='p' align='center'>
          {l1.enterEmailToReceiveLink}
        </Typography>

        <Formik 
          initialValues={{
            email: '',
          }}
          validationSchema={validationSchema} 
          onSubmit={(values) => {
            forgotPassword({
              variables: {
                email: values.email,
              },
            });
          }}
        >
          {({ handleBlur, values, touched, handleChange, errors, handleSubmit }) => (
            <form
              className={classes.form}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{
                  style:{ direction: 'ltr'}
                }}
                label={l1.email}
                name="email"
                autoComplete="email"
                autoFocus
                onBlur={handleBlur}
                onKeyUp={handleBlur}
                onChange={(e)=>{
                  handleChange(e)
                  setEmailChanged(true)
                }}
                value={values.email}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <Typography style={{ color: 'red' }}>{
                emailChanged
                  ? null
                : error?.graphQLErrors?.[0]?.extensions?.exception?.data?.data?.[0]?.messages?.[0]?.id === 'Auth.form.error.user.not-exist'
                  ? l1.noUserWithEmail
                : error && l1.forgotPasswordError
              }</Typography>
              <Button type="submit" disabled={loading || Object.keys(errors).length > 0} fullWidth variant="contained" color="primary" className={classes.submit}>
                <Typography className={classes.submitLabel} align="center">
                  { loading && <CircularProgress color="default" size={20} /> }
                  {l1.requestPasswordReset}
                </Typography>
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
