import React from 'react';

import Login from './Login';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import RTL from './components/RTL'; 
import Copyright from './components/Copyright';
import { Box } from '@material-ui/core';
import Theme from './components/Theme';
import CenterContent from './components/CenterContent';
import ForgotPassword from './ForgotPassword';
import Register from './Register';
import ResetPassword from './ResetPassword';
import RedirectIfLoggedIn from './components/RedirectIfLoggedIn';
import Logout from './Logout';


function App() {
  return (
    <RTL>
      <Theme>
        <CenterContent>
          {
            <Box>
              <BrowserRouter>
                <Switch>
                  <Route exact path="/reset-password">
                    <ResetPassword />
                  </Route>
                  <Route exact path="/logout">
                    <Logout />
                  </Route>
                  <RedirectIfLoggedIn>
                    <Switch>
                      <Route exact path="/forgot-password">
                        <ForgotPassword />
                      </Route>
                      <Route exact path="/register">
                        <Register />
                      </Route>
                      <Route exact path="/">
                        <Login />
                      </Route>
                      <Route exact path="*">
                        <Redirect to="/" />
                      </Route>
                    </Switch>
                  </RedirectIfLoggedIn>
                </Switch>
              </BrowserRouter>
              <Copyright />
            </Box>
          }
        </CenterContent>
      </Theme>
    </RTL>
  );
}

export default App;
