import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import {Link, useHistory} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { l1 } from './local.json';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Checkbox, CircularProgress, FormControlLabel, InputAdornment } from '@material-ui/core';
import PageTitle from './components/PageTitle';
import { useAuthStyles } from './style';
import {Check, Close} from '@material-ui/icons';

const CHECK_USERNAME_AVAILABILTY = gql`
  query checkUsernameAvailability($username: String!){
    isUsernameAvailable(username:$username)
  }
`;
const CHECK_EMAIL_AVAILABILTY = gql`
  query checkEmailAvailability($email: String!){
    isEmailAvailable(email:$email)
  }
`;
const REGISTER = gql`
  mutation Register($fullname: String!, $username: String!, $email: String!, $password: String!) {
    register(input: {  fullname: $fullname, username: $username, email: $email, password: $password }) {
      jwt
      user {
        email
        username
        fullname
      }
    }
  }
`;

const validationSchema = Yup.object().shape({
  fullname: Yup.string()
    .required(l1.fieldRequired)
    .test({
      message: l1.fullNamePlease,
      test: (value) => (value?.length >= 5 && value.trim().split(' ').length > 1),
    }),
  username: Yup.string()
    .required(l1.fieldRequired)
    .min(3, l1.usernameTooShort)
    .max(64, l1.usernameTooLong)
    .test({
      message: l1.validUsername,
      test: (value) => value?.match(/^[A-Za-z_][A-Za-z0-9_-]+$/),
    }),
  email: Yup.string()
    .required(l1.fieldRequired)
    .email(l1.validEmail),
  password: Yup.string()
    .required(l1.fieldRequired)
    .min(6, l1.min6)
    .max(64, l1.tooLong),
  passwordConfirmation: Yup.string()
    .required(l1.fieldRequired)
    .oneOf([Yup.ref('password'), null], l1.passwordsDoNotMatch),
  acceptedTermsOfService: Yup.bool().isTrue(),
});

export default function Register() {
  const history = useHistory();
  const [usernameChanged, setUsernameChanged] = useState(false);
  const [emailChanged, setEmailChanged] = useState(true);
  const [checkUsernameAvailability, usernameCheck] = useLazyQuery(
    CHECK_USERNAME_AVAILABILTY, 
    { 
      onCompleted: () => { setUsernameChanged(false) },
      onError: () => { setUsernameChanged(false) }
    }
  )
  const [checkEmailAvailability, emailCheck] = useLazyQuery(
    CHECK_EMAIL_AVAILABILTY,
    { onCompleted: () => { setEmailChanged(false) }}
  )
  const [register, {loading: registering, error: registerError }] = useMutation(REGISTER, {
    onCompleted: () => {
      history.push(`/?message=success.registered${process.env?.REACT_APP_NO_EMAIL_CONFIRMATION ? 'NoEmailConfirmation': ''}`)
    },
    onError: (registerError) =>   { console.error({registerError}) }
  });

  const classes = useAuthStyles();
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar
          className={classes.avatar}
          src={process.env.PUBLIC_URL + 'logo192.png'}
        />
        <Box justifyContent="center" margin={2}>
          <Link variant="body2" to={`/${window.location.search}`} className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary">
            {l1.alreadyRegistered}
          </Link>
        </Box>
        <PageTitle title={l1.registerNewAccount} />

        <Formik
          initialValues={{
            fullname: '',
            username: '',
            email: '',
            password: '',
            passwordConfirmation: '',
            acceptedTermsOfService: false,
          }}
          validateOnChange  
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await register({
              variables: {
                fullname: values.fullname.trim(),
                username: values.username,
                email: values.email,
                password: values.password,
              },
            });
          }}
        >
          {({ handleBlur, values, handleChange, touched, errors, handleSubmit }) => (
            <form
              className={classes.form}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label={l1.fullName}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                    {
                      !touched.fullname ? ''
                      : !errors.fullname ? <Check style={{color:"green"}} />
                      : <Close style={{color:"red"}} />
                    }
                    </InputAdornment>
                  ),
                }}
                name="fullname"
                autoComplete="full name"
                autoFocus
                onBlur={handleBlur}
                onKeyUp={handleBlur}
                onChange={handleChange}
                value={values.fullname}
                error={Boolean(errors.fullname)}
                helperText={errors.fullname}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                    {
                      usernameCheck.loading ? <CircularProgress color="inherit" size={20}/>
                      : !usernameChanged && usernameCheck?.data?.isUsernameAvailable === true ? <Check style={{color:"green"}} />
                      : (!usernameChanged && (usernameCheck?.data?.isUsernameAvailable === false || usernameCheck.error)) || (touched.username && errors.username) ? <Close style={{color:"red"}} />
                      : ''
                    }
                    </InputAdornment>
                  ),
                }}
                label={l1.username}
                name="username"
                autoComplete="username"
                onBlur={ async (e) => {
                  await handleBlur(e);
                  if(usernameChanged && !errors.username){
                    checkUsernameAvailability({ variables:{ username: e.target.value }})
                  }
                }}
                onChange={(e) => {
                  handleChange(e);
                  setUsernameChanged(true);
                }}
                value={values.username}
                error={
                  (usernameChanged && errors.username)
                  || (!usernameChanged && (usernameCheck.data?.isUsernameAvailable === false || usernameCheck.error))
                }
                helperText={
                  usernameChanged ? errors.username
                  : usernameCheck.error ? l1.errorWhileChecking
                  : usernameCheck.data?.isUsernameAvailable === false ? l1.usernameIsTaken
                  : null
                }
                disabled={usernameCheck.loading}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                    {
                      emailCheck.loading ? <CircularProgress color="inherit" size={20}/>
                      : !emailChanged && emailCheck?.data?.isEmailAvailable === true ? <Check style={{color:"green"}} />
                      : (!emailChanged && (emailCheck?.data?.isEmailAvailable === false || emailCheck.error)) || (touched.email && errors.email) ? <Close style={{color:"red"}} />
                      : ''
                    }
                    </InputAdornment>
                  ),
                }}
                label={l1.email}
                name="email"
                autoComplete="email"
                onBlur={ async (e) => {
                  await handleBlur(e);
                  if(emailChanged && !errors.email){
                    checkEmailAvailability({ variables:{ email: e.target.value }})
                  }
                }}
                onChange={(e) => {
                  handleChange(e);
                  setEmailChanged(true);
                }}
                value={values.email}
                error={
                  (emailChanged && errors.email)
                  || (!emailChanged && (emailCheck.data?.isEmailAvailable === false || emailCheck.error))
                }
                helperText={
                  emailChanged ? errors.email
                  : emailCheck.error ? l1.errorWhileChecking
                  : emailCheck.data?.isEmailAvailable === false ? l1.emailIsTaken
                  : null
                }
                disabled={emailCheck.loading}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label={l1.password}
                type="password"
                autoComplete="new-password"
                onBlur={handleBlur}
                onKeyUp={handleBlur}
                onChange={handleChange}
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                    {
                      !touched.password ? ''
                      : !errors.password ? <Check style={{color:"green"}} />
                      : <Close style={{color:"red"}} />
                    }
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="passwordConfirmation"
                label={l1.passwordConfirmation}
                type="password"
                onBlur={handleBlur}
                onKeyUp={handleBlur}
                onChange={handleChange}
                value={values.passwordConfirmation}
                error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                    {
                      !touched.passwordConfirmation ? ''
                      : !errors.passwordConfirmation ? <Check style={{color:"green"}} />
                      : <Close style={{color:"red"}} />
                    }
                    </InputAdornment>
                  ),
                }}
              />
              <Box mt={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="acceptedTermsOfService"
                      color="primary"
                      checked={values.acceptedTermsOfService}
                      inputProps={{ 'aria-label': 'controlled' }}
                      onChange={handleChange}
                    />
                  }
                  label={l1.acceptedTermsOfService}
                />
              </Box>
              {
                Boolean(Object.keys(errors).length === 0 && registerError)
                && <Typography style={{ color: 'red' }}>{l1.errorRegistering}</Typography>
              }
              <Button type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={
                  Object.keys(errors).length > 0
                  || usernameCheck.loading || usernameCheck.error || usernameCheck.data?.UsernameAvailable === false
                  || emailCheck.loading || emailCheck.error || emailCheck.data?.EmailAvailable === false
                  || registering
                }
              >
                <Typography className={classes.submitLabel}>
                  { registering && <CircularProgress color="default" size={20} /> }
                  {l1.register}
                </Typography>
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
