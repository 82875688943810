/* eslint-disable no-restricted-globals */
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Box, CircularProgress, InputAdornment} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { l1 } from './local.json';
import { useHistory } from 'react-router-dom';
import PageTitle from './components/PageTitle';
import { useAuthStyles } from './style';
import {Check, Close} from '@material-ui/icons';

const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $code: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    resetPassword(
      code: $code
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      jwt
      user {
        id
        username
        fullname
        email
      }
    }
  }
`;

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required(l1.fieldRequired)
    .min(6, l1.min6)
    .max(64, l1.tooLong),
  passwordConfirmation: Yup.string()
    .required(l1.fieldRequired)
    .oneOf([Yup.ref('password'), null], l1.passwordsDoNotMatch),
});

export default function ResetPassword() {
  const [resetPassword, {loading, error, }] = useMutation(RESET_PASSWORD, {
    onCompleted: (data) => { 
      console.log('ok', Boolean(data?.resetPassword?.user));
      history.push('/?message=success.passwordUpdated')
    },
    onError: (err) => { console.error(err);},
  });
  const history = useHistory();
  const code = new URLSearchParams(window.location.search).get('code')
  if(!code?.length){
    history.replace('/?message=error.noResetPasswordCodeProvided')
  }
  if(error?.graphQLErrors?.[0]?.extensions?.exception?.data?.data?.[0]?.messages?.[0]?.id === 'Auth.form.error.code.provide'){
    history.replace('/?message=error.invalidResetPasswordCode')
  }
  const classes = useAuthStyles();
  
  return (
    
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar
          className={classes.avatar}
          src={process.env.PUBLIC_URL + 'logo192.png'}
        />
        <Box justifyContent="center" margin={2}>
          <Link variant="body2" to={`/${window.location.search}`} className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary" >
            {l1.backToLogin}
          </Link>
        </Box>
        <PageTitle title={l1.resetPassword} />
        <Typography comoponent='p' align='center'>
          {l1.enterNewPasswordTwice}
        </Typography>

        <Formik 
          initialValues={{
            password: '',
            passwordConfirmation: '',
          }}
          validationSchema={validationSchema} 
          onSubmit={(values) => {
            resetPassword({
              variables: {
                code,
                password: values.password,
                passwordConfirmation: values.passwordConfirmation,
              }
            });
          }}
        >
          {({ handleBlur, values, touched, handleChange, errors, handleSubmit }) => (
            <form
              className={classes.form}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label={l1.newPassword}
                type="password"
                autoComplete="new-password"
                onBlur={handleBlur}
                onKeyUp={handleBlur}
                onChange={handleChange}
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                    {
                      !touched.password ? ''
                      : !errors.password ? <Check style={{color:"green"}} />
                      : <Close style={{color:"red"}} />
                    }
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="passwordConfirmation"
                label={l1.confirmNewPassword}
                type="password"
                onBlur={handleBlur}
                onKeyUp={handleBlur}
                onChange={handleChange}
                value={values.passwordConfirmation}
                error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                    {
                      !touched.passwordConfirmation ? ''
                      : !errors.passwordConfirmation ? <Check style={{color:"green"}} />
                      : <Close style={{color:"red"}} />
                    }
                    </InputAdornment>
                  ),
                }}
              />
              <Typography style={{ color: 'red' }}>{
                error ? l1.resetPasswordError : '' 
              }</Typography>
              <Button type="submit" disabled={loading || Object.keys(errors).length > 0} fullWidth variant="contained" color="primary" className={classes.submit}>
                <Typography className={classes.submitLabel}>
                  { loading && <CircularProgress color="default" size={20} /> }
                  {l1.resetPassword}
                </Typography>
              </Button>
            </form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
