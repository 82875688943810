import { Box } from "@material-ui/core";
export default function CenterContent({children}){
  return (
    <Box
      style={{
        width: '100vw',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
      {children}
    </Box>
    )
  }