import { Box, Link, Typography } from "@material-ui/core";
import { BiCopyright } from "react-icons/bi";
import {l1} from "../local.json"
export default function Copyright(){
  return (
    <Box pt={6} pb={16} textAlign="center" alignItems="center" flexDirection="row" display="flex" gridRowGap={2} justifyContent="center">
      <BiCopyright/> 
      <Typography variant="body2" color="textSecondary">
        {new Date().getFullYear()}{' '}
        <Link href={process.env.REACT_APP_WEBSITE_URL}>{process.env.REACT_APP_PLATFORM_NAME}</Link>
        {' - '}
        {l1.copyright}
      </Typography>
    </Box>
    )
  }