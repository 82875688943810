import { makeStyles } from "@material-ui/core"

export const useAuthStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        width: 72,
        height: 72,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    submitLabel:{
        fontSize: theme.spacing(2.5),
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "12px !important",
    }
}));