import { Typography } from '@material-ui/core'
import { useEffect } from 'react'
export default function PageTitle({title}) {
  useEffect (
    () => {
      document.title = `${title?.length ? title +' | ' : ''}${process.env.REACT_APP_PLATFORM_NAME}`
    },
    [title]
  )
  return (
    <Typography
      component="h1"
      variant="h5"
      align="center"
      style={{marginBottom:24}}
      dangerouslySetInnerHTML={{__html: title.replace('\n', '<br/>')}}
    />
  )
}