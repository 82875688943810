import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core';

const theme = responsiveFontSizes(
  createTheme({
    direction: 'rtl',
    palette: {
      primary: {
        main: "#002366"
      },
      secondary: {
        main: "#b23434"
      }
    },
    typography: {
      fontFamily: ['"Open Sans"', "Cairo", "Roboto"].join(","),
    }
  })
);


export default function Theme(props) {
  return (
      <ThemeProvider theme={theme} >
        {props.children}
      </ThemeProvider>
  );
}