import React from 'react';

import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client';
import { onError } from 'apollo-link-error';

function Apollo({ children }) {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_BACKEND_URL,
    //  uri: 'https://strapi.rwadleadership.tk/graphql',
  });

  const client = new ApolloClient({
    link: ApolloLink.from([errorLink, httpLink]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions:{
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
  });
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default Apollo;
